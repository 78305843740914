import { types } from 'mobx-state-tree';

import { PriceAmount } from '~/common';

const CouponValue = types.model('CouponValue', {
  raw: types.number,
  formatted: types.string,
});

export const CouponDiscount = types.model('CouponDiscount', {
  max: types.maybeNull(PriceAmount),
  type: types.enumeration(['percentage', 'fixed']),
  value: CouponValue,
})
  .views((self) => ({
    get hasUpperLimit() {
      return self.max !== null;
    },
  }));
