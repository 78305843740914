import { types } from 'mobx-state-tree';

import { Coupon, CouponInterface } from './';
import { PriceAmountInterface } from '~/common';
import { CouponListModel } from './types';

export const CouponList: CouponListModel = types.model('CouponList', {
  selectedCoupon: types.maybeNull(types.reference(Coupon)),
  items: types.late(() => types.array(types.reference(Coupon))),
})
  .views(self => ({
    getCouponsIssuableToProduct(productId: string): CouponInterface[] {
      return self.items.filter(({
        isIssuable,
        isApplicableToProduct,
      }) => (
        isIssuable &&
        isApplicableToProduct(productId)
      ));
    },
    getCouponUsableToProduct: (function(this: any, productId: string, productPrice: PriceAmountInterface): CouponInterface[] {
      return this.getCouponsIssuableToProduct(productId)
        .filter(({ isComeIntoRange }: CouponInterface) => isComeIntoRange(productPrice));
    }).bind(self),
  }))
  .actions(self => ({
    selectCoupon(coupon: CouponInterface | null): CouponInterface | null {
      return self.selectedCoupon = coupon;
    },
  }));
