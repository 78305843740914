import { PaymentMethod } from '~/domains/shop';

interface PaymentMethodData {
  label: string;
  value: PaymentMethod;
  iconUrl?: string;
}

export const simplePaymentMethods: PaymentMethodData[] = [
  {
    label: 'kakaopay',
    value: {
      type: 'card',
      method: 'clayful-iamport',
      pg: 'kakaopay',
    },
    iconUrl: '/paymentMethods/img-kakaopay.jpg',
  },
];

export const normalPaymentMethods: PaymentMethodData[] = [
  {
    label: '카드결제',
    value: {
      type: 'card',
      method: 'clayful-iamport',
      pg: 'nice',
    },
  },
];

export const paymentMethods = normalPaymentMethods.concat(simplePaymentMethods);
