/* eslint-disable @typescript-eslint/no-empty-function */
import { types } from 'mobx-state-tree';

const parseFunction = (value: string | null = '() => {}') => {
  try {
    const fn = Function(`'use strict'; return (${value});`)();
    return fn;
  } catch(e) {
    throw new Error(`${value} is not a valid function`);
  }
};

export const functionType = types.custom<string, Function>({
  name: 'functionType',
  fromSnapshot(value: string | null) {
    return parseFunction(value);
  },
  toSnapshot(value: Function | null) {
    return value === null ? '' : value.toString();
  },
  getValidationMessage(value: string |  null) {
    try {
      parseFunction(value);
      return '';
    } catch (e) {
      return `value "${value}" is Not a valid function ${e}`;
    }
  },
  isTargetType(value: any) {
    return value instanceof Function;
  },
});
