import { types } from 'mobx-state-tree';

import { QuantityAmount } from '~/common';

export const CouponAmount = types.model('CouponAmount', {
  total: types.maybeNull(QuantityAmount),
  issued: QuantityAmount,
})
  .views((self) => ({
    get hasExtraQuantity(): boolean {
      if (self.total === null) return true;
      return self.total.minus(self.issued).isRemaining;
    },
  }));
