import * as Yup from 'yup';
import { phoneNumberFirstDigitRegex, phoneNumberRegex } from './regex';

export const errMessage = {
  required: '필수 항목입니다.',
  invalidFirstDigit: '앞 자리는 010/011/016/017/018/019중에서 입력해주세요.',
  invalidPhoneNumber: '휴대폰번호를 정확히 입력해주세요.',
  phoneNumber: '휴대폰번호를 입력해주세요.',
  detailAddress: '배송지 정보를 정확히 입력해주세요.',
  name: '수령인 정보를 입력해주세요.',
  agreement: '주문확인 및 결제진행에 동의해주세요.',
};

const maxString = (max: number): string => `최대 ${max}자 입력하세요.`;
const minString = (min: number): string => `최소 ${min}자 입력하세요.`;

// NOTE: https://dev.clayful.io/ko/js/apis/cart/checkout-for-me
// 클레이풀 API payload 스키마를 따릅니다.
export const postcodeSchema = Yup.string()
  .max(15, maxString(15))
  .required(errMessage.detailAddress);

export const addressSchema = Yup.string()
  .min(1, minString(1))
  .max(250, maxString(250))
  .required(errMessage.detailAddress);

export const nameSchema = Yup.string()
  .min(1, minString(1))
  .max(100, maxString(100))
  .required(errMessage.name);

export const phoneDigitSchema = Yup.string()
  .notOneOf(['--'], errMessage.phoneNumber)
  .matches(phoneNumberFirstDigitRegex, errMessage.invalidFirstDigit)
  .matches(phoneNumberRegex, errMessage.invalidPhoneNumber)
  .min(12, errMessage.invalidPhoneNumber)
  .max(13, errMessage.invalidPhoneNumber)
  .required(errMessage.phoneNumber);

export const requestSchema = Yup.string()
  .max(250, maxString(250));

export const shippingMemoSchema = Yup.string();

export const paymentMethodSchema = Yup.string().oneOf(['nice', 'kakaopay']).required();

export const agreementSchema = Yup.bool().oneOf([true], errMessage.agreement);
