import Router from 'next/router';

import { CartEntryInterface } from '~/domains/cart';

export const goCart = () => Router.push('/cart');
export const goHome = () => Router.push('/');
export const goOrder = (entries: CartEntryInterface[]) => {
  Router.push(`/order?items=${entries.map(({ _id }) => _id).join(',')}`);
};
export const goError = () => Router.push('/_error');
