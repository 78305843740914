import { types, flow, getRoot } from 'mobx-state-tree';

import { CouponIssuanceResult, CouponStoreModel} from '.';
import { Coupon, CouponInterface } from '../models';
import { storeService } from '~/utils';
import { Clayful, CouponResponse } from '~/utils/clayful';
import { ShopStoreInterface } from '~/domains/shop';

const createCoupon = (couponData: CouponResponse): CouponInterface => {
  return Coupon.create({
    ...couponData,
    category: {
      ...couponData.category,
      products: couponData.category.products?.map(({ _id }) => _id),
    },
  });
};

export const CouponStore: CouponStoreModel = types.model('CouponStore', {
  coupons: types.map(Coupon),
})
  .views((self) => ({
    get shop(): ShopStoreInterface {
      return getRoot(self);
    },
  }))
  .actions(self => ({
    loadCoupons: flow<CouponInterface[], []>(function*() {
      const options = {
        query: {
          active: true,
          limit: 120,
          type: 'cart',
          slug: 'store-*',
        },
      };
      const { data } = yield Clayful.Coupon.list(options);
      const coupons = data.map((coupon: CouponResponse): CouponInterface => createCoupon(coupon));
      coupons.forEach((coupon: CouponInterface) => self.coupons.put(coupon));
      return coupons;
    }),
    loadMyCoupons: flow<CouponInterface[], []>((function*() {
      const options = {
        query: {
          limit: 120,
        },
      };
      const { data } = yield Clayful.Customer.listCouponsForMe(options);
      const coupons = data.map((coupon: CouponResponse): CouponInterface => createCoupon(coupon));
      coupons.forEach((coupon: CouponInterface) => self.coupons.put(coupon));
      return coupons;
    })),
    issueCoupon: flow<CouponIssuanceResult, [CouponInterface]>((function*(coupon: CouponInterface) {
      try {
        yield storeService.post(`/coupons/${coupon._id}/issue`);

        return { isSuccess: true };
      } catch(e) {
        return {
          isSuccess: false,
          code: e.response.data.code,
          message: e.response.data.message,
        };
      }
    }).bind(self)),
    issueCoupons: flow<CouponIssuanceResult[], [CouponInterface[]]>((function*(this: any, coupons: CouponInterface[]) {
      const candidate = coupons.map((coupon: CouponInterface) => this.issueCoupon(coupon));
      const results = yield Promise.allSettled(candidate);
      return results.map(({ value }: { value: CouponIssuanceResult }) => value);
    }).bind(self)),
    alertIssueCouponsMessage: function(results: CouponIssuanceResult[]) {
      const hasSuccessIssued = results.some(({ isSuccess }) => isSuccess);
      const message = hasSuccessIssued ? '쿠폰이 모두 발급되었습니다.' : '쿠폰이 이미 발급되었습니다.';

      self.shop.alert(message);
    },
  }));
