import { toJS } from 'mobx';
import { types, flow, getRoot } from 'mobx-state-tree';

import { mergeVariantInItem } from '../../utils';
import {
  Clayful,
  formatPhoneNumber,
  requestPayment as iamportPayment,
  OrderResponse,
} from '~/utils';
import { DateTime } from '~/common';
import {
  Order,
  ItemInterface,
  OrderShipmentInterface,
  OrderInterface,
  RefundInterface,
} from '~/domains/order';
import { PER_PAGE } from '~/constant/pagination';
import { PaymentMethod, ShopStoreInterface } from '~/domains/shop';

const getAddressInfo = (data: OrderResponse['address']['shipping']) => {
  let name = data.name.full;
  if (!name) {
    name += data.name.first || '';
    name += data.name.last || '';
  }
  return {
    postcode: data.postcode || '',
    address1: data.address1 || '',
    address2: data.address2 || '',
    name,
    phone: formatPhoneNumber({
      isFormatted: true,
      value: data.phone || data.mobile || '',
    }),
  };
};

const manufactureRefunds = (refunds: OrderResponse['refunds']) => (
  refunds.length ?
    refunds.map(({ items, shipments }, idx: number) => ({
      ...refunds[idx],
      items: items.map(({ item }) => ({
        _id: item._id,
        quantity: item.quantity.raw,
      })),
      shipments: shipments.map(({ shipment }) => shipment._id),
    })) : []
);

const createOrder = (data: OrderResponse): OrderInterface => (
  Order.create({
    _id: data._id,
    items: data.items.map(({ _id, quantity }) => ({
      _id,
      quantity: quantity.raw,
    })),
    productInfo: {
      _id: data.items[0].product._id,
      name: data.items[0].product.name,
      thumbnail: data.items[0].product.thumbnail.url,
      variants: mergeVariantInItem(data.items),
    },
    productPrice: data.total.items.price.sale,
    shippingPrice: data.total.shipping.fee.sale,
    shipments: data.shipments,
    discountedPrice: data.total.discounted,
    cancelledPrice: data.total.cancelled,
    amountPrice: data.total.amount,
    address: {
      shipping: getAddressInfo(data.address.shipping),
      billing: getAddressInfo(data.address.billing),
    },
    request: data.request,
    fulfillment: data.fulfillments?.[0],
    refunds: manufactureRefunds(data.refunds),
    transaction: data.transactions?.[0],
    status: data.status,
    createdAt: data.createdAt,
    receivedAt: data.receivedAt,
    cancellation: data.cancellation,
    customer: data.customer,
  })
);

const extractRefundableItems = (items: ItemInterface[], refunds: RefundInterface[]): { item: string; quantity: number }[] => {
  const refundableItems: ItemInterface[] = [];
  const refundedQuantity = refunds
    .reduce((acc: { [_id: string]: number }, { items: refundItems }) => {
      refundItems.forEach(({ _id, quantity }) => {
        if (acc[_id]) {
          acc[_id] += quantity;
        } else {
          acc[_id] = quantity;
        }
      });
      return acc;
    }, {});

  items.forEach((origin: ItemInterface) => {
    const copy = toJS(origin);
    const refundQuantity = refundedQuantity[copy._id];
    if (refundQuantity) {
      copy.quantity -= refundQuantity;
      if (copy.quantity === 0) return;
    }
    refundableItems.push(copy);
  });
  return refundableItems.map(({ _id: item, quantity }) => ({ item, quantity }));
};

const extractRefundableShipments = (shipments: OrderShipmentInterface[], refunds: RefundInterface[]): string[] => {
  const copyShipments = toJS(shipments);
  refunds.forEach(({ shipments }) => {
    shipments.forEach((refundShipment: string) => {
      const matchedShipmentIdx = copyShipments.findIndex(({ _id }) => refundShipment === _id);
      if (matchedShipmentIdx > -1) copyShipments.splice(matchedShipmentIdx, 1);
    });
  });
  return copyShipments.filter(({ fee }) => fee.sale.raw).map(({ _id }) => _id);
};

export const OrderStore = types
  .model('OrderStore', {
    isLoading: true,
    order: types.maybeNull(types.reference(Order)),
    orders: types.array(Order),
    page: 1,
  })
  .views((self) => ({
    get shop(): ShopStoreInterface {
      return getRoot(self);
    },
  }))
  .actions(self => ({
    fetchOrder: flow(function* (id: string) {
      const { data } = yield Clayful.Order.getForMe(id);
      const order = createOrder(data);

      const fetchOrderIndex = self.orders.findIndex(({ _id }) => _id === id);
      const isAlreadyExisted = fetchOrderIndex > -1;
      if (isAlreadyExisted) {
        self.orders[fetchOrderIndex] = order;
      } else {
        self.orders.push(order);
      }
      self.order = order;
      return order;
    }),
    clearList: () => {
      self.page = 1;
      self.orders.clear();
      self.order = null;
    },
    fetchList: flow(function* () {
      self.isLoading = true;
      const response = yield Clayful.Order.listForMe({
        query: { page: self.page, limit: PER_PAGE },
      });
      const orders = response.data;

      orders.forEach((order: OrderResponse) => {
        self.orders.push(createOrder(order));
      });
      self.isLoading = false;
    }),
    updateOrderTransaction: flow(function* (id: string) {
      const { data } = yield Clayful.Order.updateTransactionsForMe(id);
      // TODO: 무통장 입금의 경우 response 의 'transactions.vbanks'로 정보를 화면에 뿌려준다.
      return data;
    }),
    requestPayment: (paymentMethod: PaymentMethod) => {
      if (self.order) {
        iamportPayment({
          pg: paymentMethod.pg,
          paymentMethod: paymentMethod.type,
          merchantUid: self.order._id,
          name: self.order.productInfo.name,
          amount: self.order.amountPrice.raw,
          buyerEmail: self.order.customer?.email,
          buyerName: self.order.address?.shipping.name ?? '',
          buyerTel: self.order.address?.shipping.phone ?? '',
          buyerAddr: `${self.order.address?.shipping.address1} ${self.order.address?.shipping.address2}`,
          buyerPostcode: self.order.address?.shipping.postcode ?? '',
        });
      }
    },
    selectOrder: (order: OrderInterface) => {
      return self.order = order;
    },
    confirmPurchase: flow((function* () {
      try {
        const { data: { received } } = yield Clayful.Order.markAsReceivedForMe(self.order?._id ?? '');
        if (self.order) {
          self.order.receivedAt = DateTime.create({ raw: new Date().toISOString() });
          self.order.status = null;
        }

        return { isSuccess: received };
      } catch(e) {
        return {
          isSuccess: false,
          message: e.message,
        };
      }
    }).bind(self)),
    alertConfirmPurchage: (isSuccess: boolean) => {
      if (isSuccess) {
        self.shop.message.alert('구매 확정되었습니다.');
      }
    },
    refund: flow((function*(reason: string) {
      if (self.order) {
        const { items, refunds, shipments } = self.order;
        const refundableItems = extractRefundableItems(items, refunds);
        const refundableShipmments = extractRefundableShipments(shipments, refunds);
        try {
          const { data: refund } = yield Clayful.Order.requestRefundForMe(
            self.order._id, {
              reason,
              items: refundableItems,
              shipments: refundableShipmments,
            });
          self.order.refunds.push({
            status: refund.status,
            items: refund.items.map(({ item, quantity }: { item: string; quantity: number }) => ({
              _id: item,
              quantity,
            })),
            shipments: refund.shipments.map(({ shipment }: { shipment: { _id: string } }) => shipment),
          });
          return { isSuccess: true };
        } catch(e) {
          return { isSuccess: false, message: e.message };
        }
      }
      return { isSuccess: false };
    }).bind(self)),
    refundAlertMessage(isSuccess: boolean) {
      if(isSuccess) {
        self.shop.message.alert('환불요청되었습니다.');
      }
    },
  }))
  .actions(self => ({
    fetchNextList: () => {
      self.page += 1;
      self.fetchList();
    },
    cancelOrder: flow(function* (id: string) {
      yield Clayful.Order.cancelForMe(id);
      self.fetchOrder(id);
      self.shop.autoHideAlert('주문 취소되었습니다.', 1000);
    }),
  }));
