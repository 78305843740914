import { types, getRoot } from 'mobx-state-tree';
import Router from 'next/router';

import { appJsCall, trackEvent } from '~/utils';
import { goOrder } from '~/routers';
import { ShopStoreInterface } from '~/domains/shop';
import { CartEntryInterface } from '~/domains/cart';

export const ViewStore = types
  .model({
    route: '',
    query: types.frozen({}),
    selectedCartEntryIds: types.array(types.string),
  })
  .views(self => ({
    queryValue(key: string): string | null {
      const query = self.query as { [index: string]: string };
      return query[key] || null;
    },
    get shop(): ShopStoreInterface {
      return getRoot(self);
    },
  }))
  .actions(self => ({
    openCheckoutPage(entries: CartEntryInterface[]): void {
      self.selectedCartEntryIds.clear();
      goOrder(entries);
    },
    exitWeb(): void {
      self.shop.alertDialog.open({
        text: '스토어에서 나가시겠습니까?',
        onConfirm: () => {
          appJsCall('close');
          trackEvent('common', 'closeConfirm');
        },
        onCancel: () => {
          trackEvent('common', 'closeCancel');
        },
      });
    },
    goBack(): void {
      const prevUrl = window.location.href;
      Router.back();
      setTimeout(() => {
        const currentUrl = window.location.href;
        if (prevUrl === currentUrl) {
          appJsCall('close');
        }
      }, 1000);
    },
  }));
