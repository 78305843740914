const mergeVariantInItem = (product: any[]) => {
  return product.reduce((variants: any[], { total, variant, quantity }: any) => {
    return variants.concat({
      ...variant,
      price: { raw: total.price.sale.raw / quantity.raw },
      quantity: quantity.raw,
    });
  }, []);
};


export default mergeVariantInItem;
