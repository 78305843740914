import { types, flow } from 'mobx-state-tree';

import { Vendor, VendorInterface } from '../models';
import { Clayful } from '~/utils/clayful';

export const VendorStore = types
  .model('VendorStore', {
    isLoading: false,
    vendors: types.map(Vendor),
  })
  .actions(self => ({
    loadVendor: flow(function* (vendorId) {
      if (!vendorId) return;
      self.isLoading = true;
      const { data } = yield Clayful.Vendor.get(vendorId);
      const vendor: VendorInterface = Vendor.create(data);

      self.vendors.set(vendor._id, vendor);
      self.isLoading = false;

      return vendor;
    }),
  }));
