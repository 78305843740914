const isServerSide = (): boolean => {
  return typeof window === 'undefined';
};

export const setLocalStorage = (key: string, value: string): void => {
  if (!isServerSide()) {
    window.localStorage.setItem(key, value);
  }
};

export const getLocalStorage = (key: string): string | null => {
  if (isServerSide()) return null;
  return window.localStorage.getItem(key);
};

export const removeLocalStorage = (key: string): void => {
  if (!isServerSide()) {
    window.localStorage.removeItem(key);
  }
};
