import { ActionType, RequestConfigForWebViewArgs } from '.';

const postMessage = (action: ActionType) => {
  const webView = window.ReactNativeWebView;

  if (webView) {
    webView.postMessage(JSON.stringify(action));
  }
};

export const requestConfigForWebView = ({ enableToControlHardwareBackKeyOnWeb }: RequestConfigForWebViewArgs) => {
  postMessage({
    type: 'configure',
    payload: {
      enableToControlHardwareBackKeyOnWeb,
    },
  });
};

export const overrideGoBackForWebView = (goBack: Function) => {
  window.goBackForClassting = goBack;
};
