import { types } from 'mobx-state-tree';

import { Variation } from './Variation';

export const Option = types.model({
  _id: types.identifier,
  name: types.string,
  priority: types.number,
  variations: types.array(Variation),
});
