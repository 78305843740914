export const removeSpace = (str: string): string => str.replace(/\s/g, '');

export const splitHTMLTag = (text: string, tag: string): string[] => {
  const openBracketRegex = new RegExp(`<(${tag})([^>]*)>`, 'g');
  const closeBracketRegex = new RegExp(`</${tag}>`, 'g');
  const removedPTag: string = text.replace(openBracketRegex, '');
  return removedPTag.split(closeBracketRegex).filter(Boolean);
};

export const divideTextWithSeparator = (text: string, divisionStr: string): string[] => {
  return text
    .split(divisionStr)
    .reduce((acc: string[], info, idx) => {
      if (idx > 1) {
        acc[1] += `${divisionStr}${info}`;
      } else {
        acc.push(info.trim());
      }
      return acc;
    }, []);
};

export const transformTextDividedHtmlTag = ({ strWithTag, tagName, divisionStr }: {
  strWithTag: string;
  tagName: string;
  divisionStr: string;
}): string[][] => {
  const dividedLineBreak = splitHTMLTag(strWithTag, tagName);
  return dividedLineBreak.map(value => divideTextWithSeparator(value, divisionStr));
};
