import { types, getRoot, flow } from 'mobx-state-tree';

import { ProductListStoreModel } from './types';
import { ProductInterface, ProductListInterface } from '../models';
import { ProductList } from '../models/ProductList';
import { ProductsApiQuery } from '~/utils';
import { ShopStoreInterface } from '~/domains/shop';
import { ProductStoreInterface } from '~/domains/product';
import { PER_PAGE } from '~/constant/pagination';

export const ProductListStore: ProductListStoreModel = types
  .model('ProductListStore', {
    list: types.map(ProductList),
  })
  .views(self => ({
    get shop(): ShopStoreInterface {
      return getRoot(self);
    },
    getProductList: (listKey: string): ProductListInterface | null => {
      return self.list.get(listKey) ?? null;
    },
  }))
  .actions(self => ({
    addProductList: (listKey: string, products: ProductInterface[], hasMore: boolean) => {
      self.list.set(listKey, {
        items: products.map(({ _id }) => ({ product: _id })),
        hasMore,
      });
    },
    loadProducts: flow((function*(this: any, query: ProductsApiQuery, listKey: string) {
      const productList = self.getProductList(listKey);

      if (!productList || query.page !== 1) {
        const productStore: ProductStoreInterface = self.shop.productStore;
        const products: ProductInterface[] = yield productStore.loadProducts(query);
        const exposedProducts = products.filter(({ meta }) => {
          if(!meta) return true;
          return !meta.isUnExposed;
        });
        const hasMore = products.length > 0 && (products.length % PER_PAGE === 0);

        if (productList) {
          productList.hasMore = hasMore;

          exposedProducts.forEach(({ _id }) => {
            productList.items.push({ product: _id });
          });
        } else {
          this.addProductList(listKey, exposedProducts, hasMore);
        }
        return exposedProducts;
      }
      return null;
    }).bind(self)),
    fetchNextList: flow((function* (this: any, listKey: string) {
      const getProductList = self.getProductList(listKey);
      if(getProductList) {
        getProductList.page += 1;
        const query: ProductsApiQuery = {
          page: getProductList.page,
          limit: PER_PAGE,
        };
        if (listKey === 'available') query.available = true;

        const products = yield this.loadProducts(query, listKey);
        return products;
      }
      return null;
    }).bind(self)),
  }));
