import { types, getRoot, flow } from 'mobx-state-tree';
import { last } from 'lodash';

import { Product, ProductInterface } from '..';
import {
  ShippingPolicyResponse,
  ProductResponse,
  ProductWithShippingPolicy,
  ProductsApiQuery,
} from '~/utils';
import { ShopStoreInterface } from '~/domains/shop';
import { getProduct, getProducts, getShippingPolicies } from '~/utils/api/internal/internalApi';

const getShippingPolicy = async (methods: any[]) => {
  if (!methods.length || !methods?.[0]?._id) return {};

  const query = {
    method: methods[0]._id,
    limit: 1,
  };

  const response = await getShippingPolicies(query);
  if (!response.data.length) return {};
  return response.data[0].rules[0];
};

const createProduct = (data: ProductWithShippingPolicy): ProductInterface => (
  Product.create({
    ...data,
    shippingMethod: data?.shipping?.methods[0],
    coverImage: data?.catalogs?.[0]?.image,
    collection: last(data?.collections?.[0]?.path),
    discount: {
      raw: data.discount.discounted.raw,
    },
    variants: data.variants.map((variant) => ({
      ...variant,
      types: variant.types.map((type) => ({
        option: type.option._id,
        variation: type.variation._id,
      })),
      quantity: variant.quantity?.raw,
      product: data._id,
    })),
    meta: {
      ...data.meta,
      productInfoNotice: data.meta.productInfoNotice || data.meta.product_info_notice,
    },
  })
);

export const ProductStore = types
  .model('ProductStore', {
    isLoading: false,
    products: types.map(Product),
  })
  .views(self => ({
    get shop(): ShopStoreInterface {
      return getRoot(self);
    },
  }))
  .actions(self => ({
    loadProduct: flow(function* (productId) {
      self.isLoading = true;

      const { data }: { data: ProductResponse } = yield getProduct(productId);
      const shippingPolicy: ShippingPolicyResponse = yield getShippingPolicy(data.shipping.methods);
      const product = createProduct({
        ...data,
        shippingPolicy,
      });

      self.products.set(product._id, product);
      self.isLoading = false;

      return product;
    }),
    loadProducts: flow(function* (query: ProductsApiQuery) {
      self.isLoading = true;
      const { data }: { data: ProductResponse[] } = yield getProducts(query);

      const products = data.map((product) => {
        const productModel = createProduct(product);
        self.products.set(productModel._id, productModel);
        return productModel;
      });

      self.isLoading = false;

      return products;
    }),
  }));
