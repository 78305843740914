/* eslint-disable @typescript-eslint/no-empty-function */
import { types } from 'mobx-state-tree';

import { functionType } from '~/common/customTypes';

export const Dialog = types
  .model('Dialog', {
    text: '',
    cancelButtonEnabled: true,
    onClose: types.optional(functionType, () => {}),
    onCancel: types.optional(functionType, () => {}),
    onConfirm: types.optional(functionType, () => {}),
  });
