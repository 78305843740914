import { types } from 'mobx-state-tree';

import { Product } from '.';

export const ProductListItem = types.model('ProductListItem', {
  isImpress: false,
  product: types.late(() => types.reference(Product)),
})
  .actions((self) => ({
    checkImpression(bottomPosition: number, topPosition: number, viewHeight: number, unImpressRate = 0.5): boolean {
      const itemHeight = bottomPosition - topPosition;
      const unImpressedHeight = itemHeight * unImpressRate;
      const impressionStandard = bottomPosition - unImpressedHeight;

      if (impressionStandard <= viewHeight) {
        self.isImpress = true;
        return true;
      }
      return false;
    },
  }));
