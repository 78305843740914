import { types, getParent } from 'mobx-state-tree';

import {
  Variant,
  ProductInterface,
} from '~/domains/product/models';
import { ShopStoreInterface } from '~/domains/shop';
import { PriceAmount, PriceAmountInterface } from '~/common';

export const CartEntryCandidate = types
  .model('CartEntryCandidate', {
    variant: types.late(() => types.reference(Variant)),
    quantity: 1,
  })
  .views((self) => ({
    get product(): ProductInterface {
      return self.variant.product;
    },
    get totalPrice(): PriceAmountInterface {
      return PriceAmount.create({
        raw: self.quantity * self.variant.price.sale.raw,
      });
    },
    get shop(): ShopStoreInterface {
      return getParent(self, 3);
    },
  }))
  .actions((self) => ({
    increaseQuantity(number = 1): number {
      if (self.variant.quantity < self.quantity + number) {
        self.shop.alert('재고가 부족합니다');
        return self.quantity;
      }
      return (self.quantity += number);
    },
    decreaseQuantity(number = 1): number {
      if (self.quantity <= number) {
        self.shop.alert('최소 주문 수량은 1개입니다');
        return self.quantity;
      }
      return (self.quantity -= number);
    },
  }));
