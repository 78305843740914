/* eslint-disable @typescript-eslint/no-empty-function */
import { types } from 'mobx-state-tree';

import { UpdateContentDialogDto } from '.';
import { functionType } from '~/common/customTypes';

export const ContentDialogStore = types
  .model({
    isVisible: false,
    title: '',
    Contents: types.optional(functionType, () => {}),
  })
  .actions(self => ({
    close(): void {
      self.isVisible = false;
      self.title = '';
      self.Contents = () => {};
    },
    open(dialog: UpdateContentDialogDto): void {
      Object.keys(dialog).forEach((value: string) => {
        const key = value as keyof UpdateContentDialogDto;
        if (self[key] !== dialog[key]) {
          (self[key] as string | undefined | Function) = dialog[key];
        }
      });
      self.isVisible = true;
    },
  }));
