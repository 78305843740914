import { setSessionStorage, getSessionStorage } from './browserStorage';
import { CLAYFUL_TOKEN } from '~/constant';

export const getClayfulToken = () => {
  return getSessionStorage(CLAYFUL_TOKEN);
};

export const setClayfulToken = (token: string) => {
  setSessionStorage(CLAYFUL_TOKEN, token);
};
