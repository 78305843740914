import { types } from 'mobx-state-tree';

import { CouponAmount } from './CouponAmount';
import { CouponCategory } from './CouponCategory';
import { CouponDiscount } from './CouponDiscount';
import { PriceRange, DateTime, PriceAmountInterface } from '~/common';
import { dayjs } from '~/utils/dayjs';

export const Coupon = types.model('Coupon', {
  _id: types.identifier,
  name: types.string,
  type: types.enumeration(['product', 'cart', 'shipping']),
  amount: CouponAmount,
  createdAt: DateTime,
  expiresAt: types.maybeNull(DateTime),
  discount: CouponDiscount,
  price: PriceRange,
  category: CouponCategory,
})
  .views((self) => ({
    get isIssuable(): boolean {
      return self.amount.hasExtraQuantity
        && (self.expiresAt === null || dayjs().isBefore(self.expiresAt.raw));
    },
    get validDay(): string {
      const hasExpiresAt = !!(self.expiresAt);
      return hasExpiresAt
        ? `${self.createdAt.formattedDate} ~ ${self.expiresAt?.formattedDate ?? ''}`
        : '제한 없음';
    },
    isComeIntoRange(price: PriceAmountInterface) {
      return self.price.isComeIntoRange(price);
    },
    isApplicableToProduct(productId: string): boolean {
      return self.category.isApplicableToProduct(productId);
    },
  }));
