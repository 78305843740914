import { types, getRoot, flow } from 'mobx-state-tree';

import { CouponListStoreModel, CouponListType } from './types';
import { CouponList, CouponInterface, CouponListInterface } from '../models';
import { ShopStoreInterface } from '~/domains/shop';


export const CouponListStore: CouponListStoreModel = types.model('CouponListStore', {
  list: types.map(CouponList),
})
  .views(self => ({
    get shop(): ShopStoreInterface {
      return getRoot(self);
    },
    getCouponList: (listKey: CouponListType): CouponListInterface | null => {
      return self.list.get(listKey) ?? null;
    },
  }))
  .actions(self => ({
    addCouponList: (listKey: CouponListType, coupons: CouponInterface[]) => {
      self.list.set(listKey, { items: coupons.map(({ _id }) => _id) });
    },
    loadCoupons: flow((function* (this: any, listKey: CouponListType) {
      const coupons = self.getCouponList(listKey);
      if(!coupons) {
        const newCoupons = yield self.shop.coupon.loadCoupons();
        this.addCouponList(listKey, newCoupons);
      }
    }).bind(self)),
    loadMyCoupons: flow((function*(this: any) {
      const coupons = self.getCouponList('myCoupon');
      if(!coupons) {
        const newCoupons = yield self.shop.coupon.loadMyCoupons();
        const onlyCartCoupons = newCoupons.filter(({ type }: CouponInterface) => type === 'cart');
        this.addCouponList('myCoupon', onlyCartCoupons);
      }
    }).bind(self)),
  }));
