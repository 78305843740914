import { types, getSnapshot, destroy } from 'mobx-state-tree';
import { isEqual, uniq, filter, clone, find } from 'lodash';

import { CartEntryCandidate, CartEntryCandidateInterface } from '~/domains/cart/models';
import {
  Variant,
  OptionValue,
  VariantInterface,
  OptionInterface,
  VariationInterface,
  OptionValueInterface,
} from '../models';
import { extractIdsFromObjectList } from '~/utils';
import { PriceAmountInterface, PriceAmount } from '~/common';

export const OptionSelectorStore = types
  .model({
    variants: types.array(types.late(() => types.reference(Variant))),
    selectedOptionValues: types.array(OptionValue),
    cartEntryCandidates: types.array(CartEntryCandidate),
  })
  .views(self => ({
    get selectedVariant(): VariantInterface | undefined {
      return self.variants.find(({ types }) => isEqual(types, self.selectedOptionValues));
    },
    getSelectableVariations({ priority }: OptionInterface): VariationInterface[] {
      const optionValues = self.selectedOptionValues.slice(0, priority);
      const possibleVariants = filter(self.variants, { 'types': optionValues });

      return uniq(possibleVariants.map(({ types }) => types[priority].variation));
    },
    getSelectedOptionValue(option: OptionInterface): OptionValueInterface | undefined {
      return find(self.selectedOptionValues, { option });
    },
    findVariantByOptionValue(option: OptionInterface, variation: VariationInterface): VariantInterface | undefined {
      const snapshotOfOptionValues = clone(getSnapshot(self.selectedOptionValues));
      snapshotOfOptionValues.push({ option: option._id, variation: variation._id });

      return self.variants.find(v => isEqual(getSnapshot(v.types), snapshotOfOptionValues));
    },
    getCartEntryCandidateByVariant(variant: VariantInterface): CartEntryCandidateInterface | undefined {
      return find(self.cartEntryCandidates, { variant });
    },
    get isReadyToCheckout(): boolean {
      return self.cartEntryCandidates.length > 0;
    },
    get checkout(): PriceAmountInterface {
      return self.cartEntryCandidates.reduce(
        (sum, cartEntryCandidate) => sum.plus(cartEntryCandidate.totalPrice),
        PriceAmount.create(),
      );
    },
  }))
  .actions(self => {
    const increaseQuantityOfSelectedVariant = (variant: VariantInterface): number | undefined => {
      const candidate: CartEntryCandidateInterface | undefined = self.getCartEntryCandidateByVariant(variant);
      if (!candidate) return;

      return candidate.increaseQuantity(1);
    };

    const decreaseQuantityOfSelectedVariant = (variant: VariantInterface): number | undefined => {
      const candidate: CartEntryCandidateInterface | undefined = self.getCartEntryCandidateByVariant(variant);
      if (!candidate) return;

      return candidate.decreaseQuantity();
    };

    const selectVariant = (variant: VariantInterface): void => {
      if (increaseQuantityOfSelectedVariant(variant)) return;

      self.cartEntryCandidates.push({ variant: variant._id, quantity: 1 });
    };

    const removeCartEntryCandidate = (cartEntryCandidate: CartEntryCandidateInterface): void => {
      destroy(cartEntryCandidate);
    };

    const setVariants = (variants: VariantInterface[]): void => {
      self.variants.replace(variants.map(extractIdsFromObjectList));
    };

    const select = (option: OptionInterface, variation: VariationInterface): void => {
      const alreadyExist = !!self.getSelectedOptionValue(option);
      if (alreadyExist) {
        self.selectedOptionValues.forEach(optionValue => {
          if (optionValue.option.priority >= option.priority) {
            destroy(optionValue);
          }
        });
      }
      self.selectedOptionValues.push({
        option: option,
        variation: variation,
      });
      if (self.selectedVariant) {
        selectVariant(self.selectedVariant);
        self.selectedOptionValues.clear();
      }
    };

    return {
      setVariants,
      select,
      selectVariant,
      increaseQuantityOfSelectedVariant,
      decreaseQuantityOfSelectedVariant,
      removeCartEntryCandidate,
    };
  });
