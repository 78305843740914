import * as Sentry from '@sentry/node';
import { IncomingMessage } from 'http';

import { isApp } from '../device';

interface SentryRequest extends IncomingMessage {
  params?: string;
  query?: string;
  user?: {
    id: string;
    email: string;
  };
}

export const notifySentryAtServer = (err: Error, req: SentryRequest, statusCode = 500) => {
  Sentry.configureScope((scope) => {
    if (!req) {
      scope.setTag(`ssr`, 'false');
    } else {
      scope.setTag(`ssr`, 'true');
      scope.setExtra(`url`, req.url);
      scope.setExtra(`params`, req.params);
      scope.setExtra(`query`, req.query);
      scope.setExtra(`statusCode`, statusCode);
      scope.setExtra(`headers`, req.headers);

      if (req.user) {
        scope.setUser({ id: req.user.id, email: req.user.email });
      }
    }
  });
  Sentry.captureException(err);
};

export const notifySentryManually = (err: Error, extraData?: {[key: string]: string}) => {
  Sentry.withScope((scope) => {
    scope.setTag('isApp', `${isApp}`);
    if (extraData) {
      Object.keys(extraData).forEach((key) => {
        scope.setExtra(key, extraData[key]);
      });
    }
    Sentry.captureException(err);
  });
};
