import _dayjs from 'dayjs';
import 'dayjs/locale/ko';
import utc from 'dayjs/plugin/utc';

const KO_OFFSET = 9;

_dayjs.locale('ko');
_dayjs.extend(utc);

const dayjs = (value) => {
  return _dayjs(value).utcOffset(KO_OFFSET);
};

export default dayjs;
