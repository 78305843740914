const isServer = typeof window === 'undefined';

export const setSessionStorage = (key: string, value: string) => {
  if(!isServer) {
    window.sessionStorage.setItem(key, value);
  }
};

export const getSessionStorage = (key: string) => {
  if (isServer) return null;
  const result = window.sessionStorage.getItem(key);
  return JSON.parse(JSON.stringify(result));
};
