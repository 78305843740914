import { types } from 'mobx-state-tree';
import { dayjs } from '~/utils/dayjs';

export const DateTime = types.model('DateTime', {
  raw: types.string,
})
  .views(self => ({
    get formattedDate(): string {
      return dayjs(self.raw).utcOffset(9).format('YYYY.M.D');
    },
    get formattedKorDate(): string {
      return dayjs(self.raw).utcOffset(9).format('YYYY년 M월 D일');
    },
    get formattedDateTime(): string {
      return dayjs(self.raw).utcOffset(9).format('YYYY.M.D A hh:mm');
    },
  }));
