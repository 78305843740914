import { types } from 'mobx-state-tree';

export const CouponCategory = types.model('CouponCategory', {
  type: types.string,
  products: types.maybeNull(types.array(types.string)),
})
  .views((self) => ({
    isApplicableToProduct(productId: string): boolean {
      const isInProducts = self.products ?
        self.products.findIndex((_id) => _id === productId) > -1 :
        false;

      return self.type === 'any' ||
        (
          self.type === 'include' &&
          isInProducts
        );
    },
  }));
