import ReactGA from 'react-ga';

import GaEvent from './GaEvent';
import { ImpressionListType } from '.';
import { ProductInterface } from '~/domains/product';
import { CartEntryCandidateInterface } from '~/domains/cart';
import { OrderInterface, ProductInfoInterface } from '~/domains/order';
import { PgType } from '~/domains/shop';

export const trackImpression = (
  { _id: id, name }: ProductInfoInterface | ProductInterface,
  list: ImpressionListType,
  position?: number,
) => {
  ReactGA.plugin.execute('ec', 'addImpression', {
    id,
    name,
    list,
    position,
  });
  ReactGA.event({
    nonInteraction: true,
    ...GaEvent.ecommerce.impression,
  });
};

export const trackProductDetailMove = ({ _id: id, name }: ProductInterface | ProductInfoInterface, list: ImpressionListType) => {
  ReactGA.plugin.execute('ec', 'addProduct', { id, name });
  ReactGA.plugin.execute('ec', 'setAction', 'click', { list });
};

export const trackProductDetailEnter = ({ _id: id, name }: ProductInterface) => {
  ReactGA.plugin.execute('ec', 'addProduct', { id, name });
  ReactGA.plugin.execute('ec', 'setAction', 'detail');
  ReactGA.event({
    nonInteraction: true,
    ...GaEvent.ecommerce.productDetail,
  });
};

// TODO:: cartEntityCandidates를 cart라고 판단하지 않을수 있기 떄문에 Cart 기능 추가 시 변경될 수 있다.
export const trackAddToCart = (cartEntityCandidates: CartEntryCandidateInterface[]) => {
  cartEntityCandidates.forEach((cartEntityCandidate: CartEntryCandidateInterface) => {
    const variant = cartEntityCandidate.variant;
    const product = variant.product;
    ReactGA.plugin.execute('ec', 'addProduct', {
      id: product._id,
      name: product.name,
      variant: variant.allTypeOptionValue,
      price: cartEntityCandidate.totalPrice.raw,
      quantity: cartEntityCandidate.quantity,
    });
  });

  ReactGA.plugin.execute('ec', 'setAction', 'add');
};

// TODO:: Cart 기능 추가 시 현재는 한 종류의 product -> 여러 종류의 product가 될수 있기 때문에 변경될 수 있다.
export const trackCheckout = (order: OrderInterface, paymentMethod: PgType) => {
  const product = order.productInfo;
  product.variants.forEach((variant) => {
    ReactGA.plugin.execute('ec', 'addProduct', {
      id: product._id,
      name: product.name,
      variant: variant.allTypeOptionValue,
      price: variant.price.raw,
      quantity: variant.quantity,
    });
  });

  ReactGA.plugin.execute('ec', 'setAction', 'checkout', { option: paymentMethod });
};

export const trackPurchase = (transactionId: string, order: OrderInterface) => {
  const product = order.productInfo;
  product.variants.forEach((variant) => {
    ReactGA.plugin.execute('ec', 'addProduct', {
      id: product._id,
      name: product.name,
      variant: variant.allTypeOptionValue,
      price: variant.price.raw,
      quantity: variant.quantity,
    });
  });

  ReactGA.plugin.execute('ec', 'setAction', 'purchase', { id: transactionId, revenue: order.totalPrice.raw });
  ReactGA.event({
    nonInteraction: true,
    ...GaEvent.ecommerce.purchase,
  });
};
