import axios from 'axios';

import { ProductsApiQuery, ShippingPoliciesQuery } from '.';

const internalApiClient = axios.create({
  baseURL: process.env.ORIGIN,
  headers: { 'Content-Type': 'application/json' },
});

export const getProducts = async (query: ProductsApiQuery) => {
  return await internalApiClient.get(`/api/products`, {
    params: query,
    headers: { 'Content-Type': 'application/json' },
  });
};

export const getProduct = async (productId: string) => {
  return await internalApiClient.get(`/api/products/${productId}`);
};

export const getShippingPolicies = async (query: ShippingPoliciesQuery) => {
  return await internalApiClient.get('/api/shipping-policies', {
    params: query,
  });
};
