/* eslint-disable @typescript-eslint/no-empty-function */
import { types } from 'mobx-state-tree';

import { UpdatePageDialogDto } from '.';
import { functionType } from '~/common/customTypes';

export const PageDialogStore = types
  .model({
    isVisible: false,
    title: '',
    Contents: types.maybeNull(functionType),
    onClose: types.maybeNull(functionType),
  })
  .actions(self => ({
    close(): void {
      if(self.onClose) self.onClose();

      self.isVisible = false;
      self.title = '';
      self.Contents = null;
      self.onClose = null;
    },
    open(dialog: UpdatePageDialogDto): void {
      Object.keys(dialog).forEach((value: string) => {
        const key = value as keyof UpdatePageDialogDto;
        if (self[key] !== dialog[key]) {
          (self[key] as string | undefined | Function) = dialog[key];
        }
      });
      self.isVisible = true;
    },
  }));
