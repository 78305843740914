/* eslint-disable @typescript-eslint/camelcase */
import ReactGA from 'react-ga';

import GaEvent from './GaEvent';
import { Event, GaEventType } from '.';
import { sendClientEventLog, sendClientPageLog } from './clientLog';

const isServer = typeof window === 'undefined';

export const init = (trackingId: string) => {
  if (isServer) return;
  ReactGA.initialize(trackingId, {
    testMode: process.env.NODE_ENV === 'development',
  });

  ReactGA.plugin.require('ec');
};

export const trackPageViewed = (path?: string, prevUrl: string | null = null) => {
  const url = path ? path : window.location.pathname + window.location.search;

  sendClientPageLog({
    cur_page: url,
    prev_page: prevUrl,
  });
  return ReactGA.pageview(url);
};

export const trackEvent = (group: keyof GaEventType, key: string, trackEcommerce?: () => void) => {
  const gaEventGroup: {[key: string]: Event} = GaEvent[group];

  if (!gaEventGroup[key]) return;
  const event = gaEventGroup[key];
  if (typeof trackEcommerce === 'function') trackEcommerce();
  sendClientEventLog(event);
  ReactGA.event(event);
};
