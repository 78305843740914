import { types } from 'mobx-state-tree';

const VendorMeta = types.model({
  refundPolicy: types.maybeNull(types.string),
});

export const Vendor = types
  .model('Vendor', {
    _id: types.identifier,
    name: types.string,
    description: '',
    meta: types.maybeNull(VendorMeta),
  })
  .views(self => ({
    get refundPolicy(): string {
      return self.meta?.refundPolicy ?? '';
    },
  }));
