/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios';

import {
  Event,
  EventLogClientLogParams,
  PagLogParams,
  PageLogClientLogParams,
} from './';
import { getLocalStorage } from '..';
import { CLASSTING_USER } from '~/constant';
import { version } from '~/package.json';

const appVersion = `store-service@${version}`;

const sendClientLog = (url: string, params: PageLogClientLogParams | EventLogClientLogParams) => {
  if(process.env.NODE_ENV === 'development') return;
  axios.post(url, params).catch((e) => {
    console.log(`fail to send client log::${e.message}`);
  });
};

export const sendClientPageLog = (params: PagLogParams) => {
  const user = JSON.parse(getLocalStorage(CLASSTING_USER) ?? 'null');
  if (user) {
    sendClientLog(process.env.CLIENT_PAGE_LOG_URL ?? '', {
      ...params,
      id: user.id,
      role: user.role,
      client_time: new Date().getTime(),
      app_version: appVersion,
    });
  }
};

export const sendClientEventLog = (params: Event) => {
  const user = JSON.parse(getLocalStorage(CLASSTING_USER) ?? 'null');
  if (user) {
    sendClientLog(process.env.CLIENT_EVENT_LOG_URL ?? '', {
      ...params,
      id: user.id,
      role: user.role,
      client_time: new Date().getTime(),
      device: 'web.event',
      app_version: appVersion,
    });
  }
};
