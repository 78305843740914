import { types, flow } from 'mobx-state-tree';

import { getClasstingPolicy } from '~/utils';
import { Policy, PolicyType } from '~/common';

export const PolicyStore = types
  .model({
    items: types.map(Policy),
  })
  .actions(self => ({
    loadClasstingPolicy: flow(function* (type: PolicyType) {
      const response = yield getClasstingPolicy(type);

      const policy = Policy.create({
        content: response || '',
      });

      self.items.set(type, policy);
    }),
  }));
