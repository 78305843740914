import { types } from 'mobx-state-tree';

const Tracking = types.model('Tracking', {
  company: types.maybeNull(types.string),
  uid: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});

export const Fulfillment = types.model('Fulfillment', {
  status: types.enumeration(['pending', 'shipped', 'arrived']),
  tracking: types.maybeNull(Tracking),
});
