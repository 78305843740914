import { types, flow } from 'mobx-state-tree';

import {
  ViewStore,
  AlertDialogStore,
  ContentDialogStore,
  PageDialogStore,
  PolicyStore,
} from '~/common';
import { AccountStore } from '../../account';
import { CouponStore, CouponListStore } from '../../coupon';
import {
  ProductStore,
  ProductListStore,
  OptionSelectorStore,
  ProductInterface,
} from '~/domains/product';
import { CartStore, CartEntryCandidateInterface, CartEntryInterface } from '~/domains/cart';
import { OrderStore } from '~/domains/order/stores';
import { OrderFormStore } from '~/domains/orderForm';
import { Clayful } from '~/utils';
import { VendorStore } from '~/domains/vendor/stores/VendorStore';

const Message = types
  .model('Message', {
    text: '',
    isShow: false,
  })
  .actions(self => ({
    alert(message: string): void {
      self.text = message;
      self.isShow = true;
    },
    hide() {
      self.isShow = false;
    },
  }));

export const ShopStore = types
  .model('ShopStore', {
    productStore: types.optional(ProductStore, {}),
    productListStore: types.optional(ProductListStore, {}),
    cart: types.optional(CartStore, {
      entries: [],
    }),
    view: types.optional(ViewStore, {}),
    account: types.optional(AccountStore, {}),
    optionSelector: types.optional(OptionSelectorStore, {}),
    orderForm: types.optional(OrderFormStore, {}),
    orderStore: types.optional(OrderStore, {
      orders: [],
    }),
    vendorStore: types.optional(VendorStore, {}),
    policyStore: types.optional(PolicyStore, {}),
    message: types.optional(Message, {}),
    alertDialog: types.optional(AlertDialogStore, {}),
    contentDialog: types.optional(ContentDialogStore, {}),
    pageDialog: types.optional(PageDialogStore, {}),
    coupon: types.optional(CouponStore, {}),
    couponListStore: types.optional(CouponListStore, {}),
  })
  .views(self => ({
    alert(message: string) {
      self.message.alert(message);
    },
    autoHideAlert(message: string, duration: number) {
      self.message.alert(message);
      setTimeout(() => {
        self.message.hide();
      }, duration);
    },
  }))
  .actions(self => ({
    login: flow(function* () {
      try {
        yield self.account.clayfulLogin();

        return { isSuccess: true };
      } catch(e) {
        self.alertDialog.open({
          text: '에러가 발생했습니다.\n스토어를 종료하신 후, 클래스팅 > 기타탭 > 스토어로 재접속을 부탁드립니다.',
          cancelButtonEnabled: false,
        });
        return {
          isSuccess: false,
          error: e,
        };
      }
    }),
    loadProduct: flow(function* (productId: string) {
      const product: ProductInterface = yield self.productStore.loadProduct(productId);
      if (product.vendor) yield self.vendorStore.loadVendor(product.vendor._id);

      if (product.hasNoOptions) {
        self.optionSelector.selectVariant(product.variants[0]);
      }

      return product;
    }),
    checkoutImmediately: flow(function* (cartEntryCandidates: CartEntryCandidateInterface[]) {
      try {
        self.cart.clear(); // TODO: will be removed when cart feature needs
        self.cart.deselectAll();
        const entries = yield Promise.all(cartEntryCandidates.map(cartEntryCandidate => (
          self.cart.addToCart(cartEntryCandidate)
        )));
        entries.map((e: CartEntryInterface) => e.select());

        self.cart.checkout();

        return entries;
      } catch (error) {
        throw new Error('Failed to Purchase immediately');
      }
    }),
    // TODO: OrderFromStore로 이동
    purchaseOrder: flow(function* ({
      postcode,
      address1,
      address2,
      name,
      phone,
      request,
      paymentMethod,
    }) {
      const country = 'KR';
      const currency = 'KRW';
      const addressValues = address1.split(' ');
      const addressInfo = {
        name: {
          full: name,
        },
        mobile: null,
        phone,
        country,
        state: addressValues[0],
        city: addressValues[1] || addressValues[0],
        address1,
        address2,
        postcode,
      };
      const address = {
        shipping: addressInfo,
        billing: addressInfo,
      };
      const coupon = self.couponListStore.list.get('myCoupon')?.selectedCoupon;
      let discount = {};

      if (coupon) {
        if (coupon.type === 'cart') {
          discount = {
            cart: {
              coupon: coupon._id,
            },
          };
        }
      }

      const payload = {
        address,
        currency,
        paymentMethod: paymentMethod.method,
        request: request || null,
        discount,
      };
      try {
        const { data: { order } } = yield Clayful.Cart.checkoutForMe('order', payload);
        return {
          isSuccess: true,
          data: {
            pg: paymentMethod.pg,
            // NOTE: 무통장 입금 개발시, 참고: 'samsung' | 'card' | 'trans' | 'vbank' | 'phone'
            paymentMethod: paymentMethod.type,
            merchantUid: order._id,
            name: self.orderForm.order?.productInfo.name ?? '',
            amount: order.total.amount,
            buyerEmail: order.customer.email,
            buyerName: order.address.shipping.name.full,
            buyerTel: order.address.shipping.mobile || order.address.shipping.phone,
            buyerAddr: (order.address.shipping.address1 + order.address.shipping.address2),
            buyerPostcode: order.address.shipping.postcode,
          },
        };
      } catch (err) {
        return {
          isSuccess: false,
          ...err,
        };
      }
    }),
  }));
