import { types } from 'mobx-state-tree';
import { PriceAmount } from '../../../common/models/PriceAmount';

const FreeShippingPolicy = types.model({
  priceOver: types.maybeNull(
    types.optional(PriceAmount, { raw: 0 }),
  ),
});

export const ShippingPolicy = types.model({
  free: types.optional(FreeShippingPolicy, {}),
  fee: types.optional(PriceAmount, { raw: 0 }),
});
