import { types } from 'mobx-state-tree';

import ProductVariant from './ProductVariant';
import { PriceAmount, PriceAmountInterface } from '~/common';

export const ProductInfo = types.model('productInfo', {
  _id: types.identifier,
  name: '',
  thumbnail: '',
  variants: types.optional(types.array(ProductVariant), []),
})
  .views((self) => ({
    get totalPrice(): PriceAmountInterface {
      const totalPriceRaw = self.variants.reduce((sum, { totalPrice }) => sum += totalPrice.raw, 0);
      return PriceAmount.create({ raw: totalPriceRaw });
    },
  }));
