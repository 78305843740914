import { FormatPhoneNumberParamType } from '.';
import { onlyNumberRegex } from '../regex';

export const splitPhoneNumber = (value: string): string[] => {
  const onlyNumberValue = value.replace(onlyNumberRegex, '');
  const isMiddleThreeDigit = onlyNumberValue.length === 10;
  const middleDigitEndSplitLength = isMiddleThreeDigit ? 6 : 7;

  return [
    onlyNumberValue.substring(0, 3),
    onlyNumberValue.substring(3, middleDigitEndSplitLength),
    onlyNumberValue.substring(middleDigitEndSplitLength, middleDigitEndSplitLength + 4),
  ];
};

export const formatPhoneNumber = ({ isFormatted, value }: FormatPhoneNumberParamType): string => {
  if (!value) return '';
  if (isFormatted) {
    return splitPhoneNumber(value).join('-');
  } else {
    return value.replace(onlyNumberRegex, '');
  }
};
