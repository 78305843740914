import { getUA, isIOS, isSafari } from 'react-device-detect';

import { classtingRnVersionRegex } from './regex';
import { APP_JSCALL_SCHEME } from '~/constant';

declare global {
  interface Window {
    ctandroid: any;
    setToken: any;
    setUserAndToken: any;
    ReactNativeWebView: any;
    goBackForClassting: any;
  }
}

export const isIosWebView = (isIOS && !isSafari);
export const isClasstingApp = !!(
  getUA.match(/classting_android/i)
  || getUA.match(/classting_iphone/i)
);
export const isApp = (isClasstingApp || isIosWebView);

const lowerCaseUserAgent = getUA.toLowerCase();
const versionMatch = classtingRnVersionRegex.exec(lowerCaseUserAgent);
export const appVersion = versionMatch ? Number(versionMatch.slice(1, 4).join('')) : 0;

export const appJsCall = (url?: string, protocol = APP_JSCALL_SCHEME): void => {
  if (!isApp) {
    console.log(`app js call : ${protocol + url}`);
    return;
  }
  if (isIOS) {
    window.location.href = protocol + url;
  } else {
    if (!window.ctandroid) {
      // To Do for under android 4.2
      window.prompt(protocol + url);
    } else {
      window.ctandroid.submitFromWeb(protocol + url);
    }
  }
};
