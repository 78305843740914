import { types } from 'mobx-state-tree';

import { ProductInfo } from './ProductInfo';
import { Fulfillment } from './Fulfillment';
import { Refund } from './Refund';
import { Cancellation } from './Cancellation';
import { Customer } from './Customer';
import { Transaction } from './Transaction';
import { AddressInfo, PriceAmount, DateTime } from '~/common';
import {
  PLACED,
  CANCELLED,
  RECEIVED,
  REFUND_REQUESTED,
  REFUNDED,
  PARTIALLY_REFUNDED,
  PENDING,
  SHIPPED,
  ARRIVED,
} from '~/constant/orderStatus';

const enumOrderStatusType = types.enumeration([
  'placed',
  'cancelled',
  'paid',
  'under-paid',
  'over-paid',
  'refunded',
  'partially-refunded',
  'under-refunded',
  'over-refunded',
]);

const Address = types.model('Address', {
  billing: AddressInfo,
  shipping: AddressInfo,
});

// TODO: cart 기능 추가 시 develop 필요
export const Item = types.model('Item', {
  _id: types.identifier,
  quantity: types.number,
});

export const Shipment = types.model('Shipment', {
  _id: types.string,
  fee: types.model({
    sale: PriceAmount,
  }),
});

export const Order = types
  .model('Order', {
    _id: types.identifier,
    items: types.array(Item),
    productInfo: ProductInfo,
    productPrice: PriceAmount,
    shippingPrice: PriceAmount,
    shipments: types.array(Shipment),
    discountedPrice: PriceAmount,
    cancelledPrice: types.optional(PriceAmount, { raw: 0 }),
    amountPrice: PriceAmount,
    address: types.maybeNull(Address),
    request: types.maybeNull(types.string),
    fulfillment: types.maybeNull(Fulfillment),
    refunds: types.array(Refund),
    transaction: types.maybeNull(Transaction),
    status: types.maybeNull(enumOrderStatusType),
    createdAt: types.maybeNull(DateTime),
    receivedAt: types.maybeNull(DateTime),
    vendorName: '',
    cancellation: types.maybeNull(Cancellation),
    customer: types.maybeNull(Customer),
  })
  .views((self) => ({
    get orderStatus() {
      if (self.status === 'placed') return PLACED;
      if (self.status === 'cancelled') return CANCELLED;
      if (self.receivedAt !== null) return RECEIVED;
      if (
        self.status === 'under-refunded' ||
        self.status === 'over-refunded' ||
        self.refunds.map(({ status }) => status).includes('requested')
      ) return REFUND_REQUESTED;
      if (self.status === 'partially-refunded') return PARTIALLY_REFUNDED;
      if (self.status === 'refunded') return REFUNDED;
      if (self.fulfillment === null || self.fulfillment?.status === 'pending') {
        return PENDING;
      }
      if (self.fulfillment?.status === 'shipped') return SHIPPED;
      if (self.fulfillment?.status === 'arrived') return ARRIVED;
      return '';
    },
    get totalPrice() {
      return self.amountPrice.minus(self.cancelledPrice);
    },
  }))
  .views((self) => ({
    get korOrderStatus() {
      switch (self.orderStatus) {
        case PLACED:
          return '결제대기';
        case CANCELLED:
          return '주문취소';
        case PENDING:
          return '배송대기';
        case SHIPPED:
          return '배송중';
        case ARRIVED:
          return '배송완료';
        case RECEIVED:
          return '구매확정';
        case REFUND_REQUESTED:
          return '환불요청중';
        case REFUNDED:
          return '환불완료';
        case PARTIALLY_REFUNDED:
          return '부분환불';
        default: return '';
      }
    },
    get avialableReOrder() {
      return self.orderStatus === PLACED;
    },
    get isCancelledOrder() {
      return self.orderStatus === CANCELLED;
    },
    get availableRefundRequested() {
      const availableStates = [PENDING, SHIPPED, ARRIVED, PARTIALLY_REFUNDED];
      return availableStates.indexOf(self.orderStatus) > -1;
    },
    get availableConfirmPurchase() {
      return self.orderStatus === ARRIVED || self.orderStatus === PARTIALLY_REFUNDED;
    },
  }));
