import { InitialStateType } from '~/domains';
import { ShopStoreSnapshotOut } from '~/domains/shop';

export const GET_PRODUCT = 'product/GET_PRODUCT';
export const GET_PRODUCTS = 'product/GET_PRODUCTS';

export const getProductAction = ({
  productStore,
  optionSelector,
  vendorStore,
}: ShopStoreSnapshotOut) => ({
  type: GET_PRODUCT,
  payload: {
    productStore,
    optionSelector,
    vendorStore,
  },
});

export const getProductsAction = ({
  productStore,
  productListStore,
}: ShopStoreSnapshotOut) => ({
  type: GET_PRODUCTS,
  payload: {
    productStore,
    productListStore,
  },
});

export const reducer = (state: ShopStoreSnapshotOut, action: InitialStateType) => {
  switch(action.type) {
    case GET_PRODUCT: {
      return {
        ...state,
        ...action.payload,
        productStore: {
          products: {
            ...state.productStore.products,
            ...action.payload.productStore.products,
          },
        },
      };
    }
    case GET_PRODUCTS: {
      return {
        ...state,
        ...action.payload,
        productStore: {
          products: {
            ...state.productStore.products,
            ...action.payload.productStore.products,
          },
        },
      };
    }
    default:
      return { ...state, ...action.payload };
  }
};
