import axios from 'axios';

import { PolicyType } from '~/common';


const classtingPolicy = {
  terms: process.env.CLASSTING_POLICY_MD_TERMS,
  privacy: process.env.CLASSTING_POLICY_MD_PRIVACY,
};

export const getClasstingPolicy = async (type: PolicyType) => {
  try {
    const { data } = await axios.get(classtingPolicy[type] ?? '');

    if (data && typeof data === 'string') {
      return data;
    }

    return null;
  } catch(e) {
    return null;
  }
};
