import { types, flow, getRoot } from 'mobx-state-tree';

import {
  Clayful,
  changeClayfulConfigWithCustomer,
  formatPhoneNumber,
  auth as storeServiceAuth,
  setToken as setTokenForStoreService,
  setClayfulToken as setClayfulTokenAtStorage,
} from '~/utils';
// TODO: will be deprecated
import { AddressInfo } from '~/common';
import { ShopStoreInterface } from '~/domains/shop';

export const AccountStore = types
  .model({
    classtingToken: types.maybeNull(types.string),
    clayfulToken: types.maybeNull(types.string),
    addressInfo: types.maybeNull(AddressInfo),
  })
  .views(self => ({
    get shop(): ShopStoreInterface {
      return getRoot(self);
    },
    get isLogin() {
      return !!(self.classtingToken && self.clayfulToken);
    },
  }))
  .actions(self => {
    const setClasstingToken = (token: string) => {
      self.classtingToken = token;
      setTokenForStoreService(token);
    };

    const setClayfulToken = (token: string) => {
      self.clayfulToken = token;
      setClayfulTokenAtStorage(token);
      changeClayfulConfigWithCustomer(token);
    };

    const getRecentOrderShippingInfo = flow(function* (limit = 1) {
      const response = yield Clayful.Order.listForMe({
        query: { limit },
      });
      if (response.data.length) {
        const orderData = response.data[0].address.billing;
        self.addressInfo = AddressInfo.create({
          postcode: orderData.postcode || '',
          address1: orderData.address1 || '',
          address2: orderData.address2 || '',
          name: orderData.name?.full || orderData.name?.first + orderData.name?.last || '',
          phone: formatPhoneNumber({
            isFormatted: true,
            value: orderData.phone || orderData.mobile || '',
          }),
        });
      }
    });

    const clayfulLogin = flow((function* (this: any) {
      if(self.isLogin) return;
      const data = yield storeServiceAuth();
      const { clayfulToken } = data;
      this.setClayfulToken(clayfulToken);
    }).bind(self));

    return ({
      setClasstingToken,
      setClayfulToken,
      getRecentOrderShippingInfo,
      clayfulLogin,
    });
  });
