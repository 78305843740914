import { types } from 'mobx-state-tree';
import { isEmpty } from 'lodash';

import { ShippingPolicy } from './ShippingPolicy';
import { Option } from './Option';
import { Price } from './Price';
import { PriceAmount } from '../../../common/models/PriceAmount';
import { ShippingMethod } from './ShippingMethod';
import { Variant } from './Variant';
import { CoverImage } from './CoverImage';
import { Thumbnail } from './Thumbnail';
import { Collection } from './Collection';
import { ProductMeta } from './ProductMeta';
import { VendorProfile } from '~/domains/vendor/models/VendorProfile';
import { transformTextDividedHtmlTag } from '~/utils/help/string';

export const Product = types
  .model('Product', {
    _id: types.identifier,
    name: types.string,
    thumbnail: types.maybeNull(Thumbnail),
    summary: types.string,
    description: types.string,
    collection: types.maybeNull(Collection),
    price: Price,
    discount: PriceAmount,
    shippingMethod: types.maybeNull(ShippingMethod),
    shippingPolicy: types.maybeNull(ShippingPolicy),
    coverImage: types.maybeNull(CoverImage),
    vendor: types.maybeNull(VendorProfile),
    variants: types.array(Variant),
    options: types.array(Option),
    meta: types.maybeNull(ProductMeta),
    available: true,
  })
  .views(self => ({
    get convertedInfoNotice() {
      return transformTextDividedHtmlTag({
        strWithTag: self.meta?.productInfoNotice || '',
        tagName: 'p',
        divisionStr: ':',
      });
    },
    get discountPercentage(): number {
      return Math.floor(self.discount.raw / self.price.original.raw * 100);
    },
    get hasMultiOptions(): boolean {
      return !isEmpty(self.options);
    },
    get hasNoOptions(): boolean {
      return isEmpty(self.options);
    },
    get hasSingleVariant(): boolean {
      return self.variants.length === 1;
    },
    get titleAtList(): string {
      return self.meta?.titleAtList || self.name;
    },
    get subtitleAtList(): string {
      return self.meta?.subtitleAtList || self.summary;
    },
    get isSoldOut(): boolean {
      return self.variants.every(({ isSoldOut }) => isSoldOut);
    },
  }));
