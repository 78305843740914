import Clayful from 'clayful';

const defaultConfig = {
  client: process.env.CLAYFUL_CLIENT_KEY,
  customer: '',
  debugLanguage: 'ko',
};

export const changeClayfulConfigWithCustomer = (customerToken: string) => {
  Clayful.config({
    ...defaultConfig,
    customer: customerToken,
  });
};

type LoginParamType = {
  userId: string;
  email?: string;
  password: string;
};

export const clayfulLogin = async ({ userId, email, password }: LoginParamType) => {
  const { data } = await Clayful.Customer.authenticate({
    userId,
    password,
    email,
  });
  changeClayfulConfigWithCustomer(data.token);
  return data;
};

Clayful.config(defaultConfig);

export default Clayful;
