import { types } from 'mobx-state-tree';

import { ShippingMethod, Variant, ProductInterface } from '~/domains/product/models';

export const CartEntry = types
  .model('CartEntry', {
    _id: types.identifier,
    quantity: 0,
    variant: types.reference(Variant),
    shippingMethod: types.reference(ShippingMethod),
    selected: false,
  })
  .views((self) => ({
    get product(): ProductInterface {
      return self.variant.product;
    },
  }))
  .actions(self => ({
    increaseQuantity(number = 1): number {
      return self.quantity += number;
    },
    select(): void {
      self.selected = true;
    },
    deselect(): void {
      self.selected = false;
    },
  }));
