import { flow, types } from 'mobx-state-tree';

import { mergeVariantInItem } from '../../utils';
import { Order } from '~/domains/order';
import { Clayful } from '~/utils/clayful';
import { CouponInterface } from '~/domains/coupon';
import { PriceAmount } from '~/common';

export const OrderFormStore = types
  .model('OrderFormStore', {
    order: types.maybeNull(Order),
    couponDiscount: types.maybeNull(PriceAmount),
  })
  .actions(self => ({
    initialize: flow(function* () {
      const response = yield Clayful.Cart.getForMe();
      const { cart } = response.data;
      const { data: vendorData } = yield Clayful.Vendor.get(cart.items[0].vendor._id);

      self.order = Order.create({
        _id: cart.items[0]._id,
        items: cart.items.map(({ _id, quantity }: {
          _id: string;
          quantity: { raw: number };
        }) => ({ _id, quantity: quantity.raw })),
        productInfo: {
          _id: cart.items[0].product._id,
          name: cart.items[0].product.name,
          thumbnail: cart.items[0].product.thumbnail.url,
          variants: mergeVariantInItem(cart.items),
        },
        productPrice: cart.total.items.price.sale,
        shippingPrice: cart.total.shipping.fee.sale,
        discountedPrice: cart.total.discounted,
        amountPrice: cart.total.amount,
        vendorName: vendorData.name,
      });
      return true;
    }),
    calculateOrderPrice: flow(function*({
      postcode = '',
      address1 = '',
      coupon = null,
    }: {
      postcode?: string | '';
      address1?: string | '';
      coupon?: CouponInterface | null;
    }) {
      let address = {};
      let discount = {};

      if (postcode && address1) {
        const addressValues = address1.split(' ');
        address = {
          shipping: {
            postcode,
            address1,
            country: 'KR',
            state: addressValues[0],
            city: addressValues[1] || addressValues[0],
          },
        };
      }

      if (coupon) {
        if (coupon.type === 'cart') {
          discount = {
            cart: {
              coupon: coupon._id,
            },
          };
        }
      }
      const response = yield Clayful.Cart.getForMe({ address, discount });
      const { cart } = response.data;
      if (self.order) {
        self.order.shippingPrice = cart.total.shipping.fee.sale;
        self.order.amountPrice = cart.total.amount;
        self.order.discountedPrice = cart.total.discounted;
        self.couponDiscount = PriceAmount.create({
          raw: self.order.productInfo.totalPrice.raw - cart.total.items.price.sale.raw,
        });
      }
    }),
  }));
