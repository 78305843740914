import { types } from 'mobx-state-tree';

import { PriceAmountInterface } from '.';
import { convertKRW } from '~/utils/help/number';

export const PriceAmount = types
  .model('PriceAmount', {
    raw: 0,
  })
  .views(self => ({
    get formatted(): string {
      return `${convertKRW(self.raw)}원`;
    },
    get formattedAmount(): string {
      return convertKRW(self.raw);
    },
    get isNonZero(): boolean {
      return self.raw !== 0;
    },
    get isBiggerThanZero(): boolean {
      return self.raw > 0;
    },
    get isLessThanZero(): boolean {
      return self.raw < 0;
    },
    plus(operand: PriceAmountInterface): PriceAmountInterface {
      return PriceAmount.create({ raw: self.raw + operand.raw });
    },
    minus(operand: PriceAmountInterface): PriceAmountInterface {
      return PriceAmount.create({ raw: self.raw - operand.raw });
    },
  }));
