import { types } from 'mobx-state-tree';

import { previousPolicyText, markdownLinkText } from '~/utils';

const removePreviousPolicyText = (text: string): string => {
  return text.replace(previousPolicyText, '');
};

const removeMarkdownLink = (text: string): string => {
  return text.replace(markdownLinkText, '$1');
};

export const Policy = types
  .model('Policy', {
    content: '',
  })
  .views(self => ({
    get formatted(): string {
      const removedPreviousLink = removePreviousPolicyText(self.content);
      return removeMarkdownLink(removedPreviousLink);
    },
  }));
