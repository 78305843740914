/* eslint-disable @typescript-eslint/no-empty-function */
import { types, applySnapshot } from 'mobx-state-tree';

import { Dialog } from '../models';
import { UpdateAlertDialogDto } from '.';

export const AlertDialogStore = types
  .model({
    isVisible: false,
    dialog: types.optional(Dialog, {}),
  })
  .actions(self => ({
    reset(): void {
      applySnapshot(self.dialog, {});
    },
    close(): void {
      self.isVisible = false;
      self.dialog.onClose();
      this.reset();
    },
    confirm(): void {
      self.dialog.onConfirm();
      this.close();
    },
    cancel(): void {
      self.dialog.onCancel();
      this.close();
    },
    open(dialog: UpdateAlertDialogDto): void {
      Object.keys(dialog).forEach((value: string) => {
        const key = value as keyof UpdateAlertDialogDto;
        if (self.dialog[key] !== dialog[key]) {
          (self.dialog[key] as string | boolean | undefined | Function) = dialog[key];
        }
      });
      self.isVisible = true;
    },
  }));
