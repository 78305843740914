import { useContext, createContext, useMemo } from 'react';
import { useStaticRendering } from 'mobx-react-lite';
import { applySnapshot, getSnapshot, Instance } from 'mobx-state-tree';
import { NextRouter } from 'next/router';

import { reducer } from '~/reducers';
import { ShopStore } from '~/domains/shop';

let store: ShopInstance;

export type ShopInstance = Instance<typeof ShopStore>;
const ShopStoreContext = createContext<null | ShopInstance>(null);

export const Provider = ShopStoreContext.Provider;

export function useShopStore(): ShopInstance {
  const store: ShopInstance | null = useContext(ShopStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null');
  }
  return store;
}

const isServer = typeof window === 'undefined';
useStaticRendering(isServer);

export function initializeStore(snapshot: InitialStateType | null = null): ShopInstance {
  const _store = store ?? ShopStore.create();
  if (snapshot) {
    applySnapshot(_store, reducer(getSnapshot(_store), snapshot));
  }
  if (isServer) return _store;
  if (!store) store = _store;
  return store;
}

const getView = (snapshot: InitialStateType | null = null, { route, query }: NextRouter) => {
  return useMemo(() => {
    const view = snapshot?.payload?.view || {};

    return ({ ...view, route, query });
  }, [route, query]);
};

export function useStore(router: NextRouter, initialState?: InitialStateType): ShopInstance {
  const view = getView(initialState, router);

  return useMemo(() => {
    const payload = {
      ...initialState?.payload ?? {},
      view,
    };
    return initializeStore({ ...initialState, payload });
  }, [initialState, view ]);
}

export interface InitialStateType {
  type?: string;
  payload: any;
}
