import { types, getParent, flow } from 'mobx-state-tree';

import { CartEntry, CartEntryCandidateInterface, CartEntryInterface } from '../models';
import { Clayful } from '~/utils/clayful';
import { ShopStoreInterface } from '~/domains/shop';

export const CartStore = types
  .model('CartStore', {
    entries: types.array(CartEntry),
  })
  .views(self => ({
    get shop(): ShopStoreInterface {
      return getParent(self);
    },
    get selectedEntries(): CartEntryInterface[] {
      return self.entries.filter(e => e.selected);
    },
  }))
  .actions(self => ({
    addToCart: flow(function* (candidate: CartEntryCandidateInterface) {
      const { data } = yield Clayful.Cart.addItemForMe({
        product: candidate.product._id,
        variant: candidate.variant._id,
        shippingMethod: candidate.product.shippingMethod?._id ?? '',
        quantity: candidate.quantity,
      });

      self.entries.push({
        _id: data._id,
        variant: data.variant,
        shippingMethod: data.shippingMethod,
        quantity: data.quantity,
      });

      return self.entries[self.entries.length - 1];
    }),
    selectAll(): void {
      self.entries.map(e => e.select());
    },
    deselectAll(): void {
      self.entries.map(e => e.deselect());
    },
    checkout(): void {
      self.shop.view.openCheckoutPage(self.selectedEntries);
    },
    clear: flow(function* () {
      self.entries.clear();
      yield Clayful.Cart.emptyForMe();
    }),
  }));
