import { types } from 'mobx-state-tree';

import { PriceAmount, PriceAmountInterface } from '~/common';

const ProductVariant = types.model('ProductVariant', {
  _id: types.identifier,
  price: PriceAmount,
  quantity: types.number,
  types: types.array(types.model({
    option: types.model({
      _id: types.identifier,
      name: types.string,
    }),
    variation: types.model({
      _id: types.identifier,
      value: types.string,
    }),
  })),
})
  .views(self => ({
    get allTypeOptionValue() {
      return self.types.map(({ option, variation }) => `${option.name} : ${variation.value}`).join(', ');
    },
    get totalPrice(): PriceAmountInterface {
      return PriceAmount.create({ raw: self.price.raw * self.quantity });
    },
  }));

export default ProductVariant;
