const color = {
  error: '#f1011d',
  backgroundColor: '#f5f6f7',
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
  },
  green: {
    50: '#FBFFFE',
    100: '#C8FFF1',
    200: '#95FFE4',
    300: '#62FFD8',
    400: '#2FFFCB',
    500: '#00FBBC',
    600: '#00C896',
    700: '#009570',
    800: '#00624A',
    900: '#002F23',
  },
  blue: {
    50: '#E2F1FD',
    100: '#B2DBFB',
    200: '#82C4F8',
    300: '#51ADF6',
    400: '#2196F3',
    500: '#0C7CD6',
    600: '#0960A5',
    700: '#064475',
    800: '#042845',
    900: '#010C14',
  },
};

export default color;
