import { types } from 'mobx-state-tree';

import { QuantityAmountInterface } from '.';

export const QuantityAmount = types.model('QuantityAmount', {
  raw: types.number,
})
  .views(self => ({
    get isRemaining(): boolean {
      return self.raw > 0;
    },
    minus(operand: QuantityAmountInterface): QuantityAmountInterface {
      return QuantityAmount.create({ raw: self.raw - operand.raw });
    },
  }));
