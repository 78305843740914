// GA 구글 시트
// https://docs.google.com/spreadsheets/d/137Ye30tmktmFI5-jMJpvioDD2Vp1CUOhPEneArB_xMU/edit#gid=1736417317
const GaEvent = {
  common: {
    closeConfirm: {
      'action': 'Click',
      'category': '스토어 이탈(Dialog)',
      'label': '스토어 닫기 버튼 > 스토어이탈컨펌 > 확인',
    },
    closeCancel: {
      'action': 'Click',
      'category': '스토어 이탈(Dialog)',
      'label': '스토어 닫기 버튼 > 스토어이탈컨펌 > 취소',
    },
  },
  coupon: {
    openCouponDialogToIssue: {
      'action': 'Click',
      'category': '쿠폰발급',
      'label': '상품상세 > 쿠폰받기 버튼',
    },
    issueAllCoupons: {
      'action': 'Click',
      'category': '쿠폰발급',
      'label': '쿠폰발급모달 > 쿠폰모두받기 버튼',
    },
    closeCouponDialogFromIssuing: {
      'action': 'Click',
      'category': '쿠폰발급',
      'label': '쿠폰발급모달 > 닫기 버튼',
    },
    cancelCoupon: {
      'action': 'Click',
      'category': '쿠폰사용',
      'label': '주문서 > 선택취소 버튼',
    },
    changeCoupon: {
      'action': 'Click',
      'category': '쿠폰사용',
      'label': '주문서 > 쿠폰변경 버튼',
    },
    openCouponDialogToSelect: {
      'action': 'Click',
      'category': '쿠폰사용',
      'label': '주문서 > 쿠폰선택 버튼',
    },
    selectCoupon: {
      'action': 'Click',
      'category': '쿠폰사용',
      'label': '쿠폰선택모달 > 쿠폰선택 버튼',
    },
    closeCouponDialogFromSelecting: {
      'action': 'Click',
      'category': '쿠폰사용',
      'label': '쿠폰선택모달 > 닫기 버튼',
    },
  },
  error: {
    goBackFrom404: {
      'action': 'Click',
      'category': '오류확인',
      'label': '404 에러 > 이전 버튼',
    },
    goBackFromNot404: {
      'action': 'Click',
      'category': '오류확인',
      'label': '접속에러 > 이전버튼',
    },
  },
  footer: {
    terms: {
      'action': 'Click',
      'category': '풋터_사용자액션',
      'label': '풋터 > 이용약관',
    },
    policy: {
      'action': 'Click',
      'category': '풋터_사용자액션',
      'label': '풋터 > 개인정보처리방침',
    },
    businessOperator: {
      'action': 'Click',
      'category': '풋터_사용자액션',
      'label': '풋터 > 사업자정보확인',
    },
  },
  orderDetail: {
    order: {
      'action': 'Click',
      'category': '주문상세_사용자액션',
      'label': '주문상세 > 결제 방식 > 결제하기 버튼',
    },
    openPaymentMethods: {
      'action': 'Click',
      'category': '주문상세_사용자액션',
      'label': '주문상세 > 결제하기 버튼',
    },
    closePaymentMethods: {
      'action': 'Click',
      'category': '주문내역_사용자액션',
      'label': '주문내역 > 결제하기 버튼 > 닫기영역',
    },
    kakaopayPaymentMethod: {
      'action': 'Click',
      'category': '주문상세_사용자액션',
      'label': '주문내역 > 결제하기 버튼 > 결제 방식(kakaopay)',
    },
    nicePaymentMethod: {
      'action': 'Click',
      'category': '주문상세_사용자액션',
      'label': '주문내역 > 결제하기 버튼 > 결제 방식(카드)',
    },
    cancel: {
      'action': 'Click',
      'category': '주문상세_사용자액션',
      'label': '주문상세 > 취소하기 버튼',
    },
    refund: {
      'action': 'Click',
      'category': '주문상세_사용자액션',
      'label': '주문상세 > 환불요청 버튼',
    },
    confirm: {
      'action': 'Click',
      'category': '주문상세_사용자액션',
      'label': '주문상세 > 구매확정버튼',
    },
    productInfo: {
      'action': 'Click',
      'category': '주문상세_상품정보',
      'label': '주문상세 > 상품정보 > 상품영역',
    },
    trackingNumber: {
      'action': 'Click',
      'category': '주문상세_배송정보',
      'label': '주문상세 > 배송정보 > 운송장번호 복사',
    },
    refundWithReason: {
      'action': 'Click',
      'category': '환불요청',
      'label': '주문상세 > 환불요청 > 환불사유선택모달 > 환불요청하기 버튼',
    },
    closeRefundReason: {
      'action': 'Click',
      'category': '환불요청',
      'label': '주문상세 > 환불요청 > 환불사유선택모달 > 닫기 버튼',
    },
    close: {
      'action': 'Click',
      'category': '주문상세 이탈',
      'label': '주문상세 > Topbar > 닫기버튼',
    },
    goBack: {
      'action': 'Click',
      'category': '주문상세 이탈',
      'label': '주문상세 > Topbar > 뒤로가기버튼',
    },
    cancelOrderConfirm: {
      'action': 'Click',
      'category': '주문취소(Dialog)',
      'label': '주문상세 > 주문취소 > 취소컨펌 > 확인',
    },
    cancelOrderCancel: {
      'action': 'Click',
      'category': '주문취소(Dialog)',
      'label': '주문상세 > 주문취소 > 취소컨펌 > 취소',
    },
    confirmOrderConfirm: {
      'action': 'Click',
      'category': '구매확정(Dialog)',
      'label': '주문상세 > 구매확정 > 구매확정컨펌 > 확인',
    },
    confirmOrderCancel: {
      'action': 'Click',
      'category': '구매확정(Dialog)',
      'label': '주문상세 > 구매확정 > 구매확정컨펌 > 취소',
    },
  },
  orderFail: {
    retryOrder: {
      'action': 'Click',
      'category': '주문실패후 이동',
      'label': '주문실패 > 다시시도 버튼',
    },
    close: {
      'action': 'Click',
      'category': '주문실패 이탈',
      'label': '주문실패 > Topbar > 닫기버튼',
    },
    goBack: {
      'action': 'Click',
      'category': '주문실패 이탈',
      'label': '주문실패 > Topbar > 뒤로가기버튼',
    },
  },
  orderForm: {
    spreadProductInfo: {
      'action': 'Click',
      'category': '주문_상품정보',
      'label': '주문서 > 상품정보 펼치기',
    },
    searchAddress: {
      'action': 'Click',
      'category': '주문_배송정보',
      'label': '주문서 > 주소검색 버튼',
    },
    kakaopayPaymentMethod: {
      'action': 'Click',
      'category': '주문_결제정보',
      'label': '주문서 > 결제 방식(kakaopay)',
    },
    nicePaymentMethod: {
      'action': 'Click',
      'category': '주문_결제정보',
      'label': '주문서 > 결제 방식(카드)',
    },
    order: {
      'action': 'Click',
      'category': '주문_결제정보',
      'label': '주문서 > 결제하기 버튼',
    },
    close: {
      'action': 'Click',
      'category': '주문서 이탈',
      'label': '주문서 > Topbar > 닫기버튼',
    },
    goBack: {
      'action': 'Click',
      'category': '주문서 이탈',
      'label': '주문서 > Topbar > 뒤로가기버튼',
    },
    emptyAddress: {
      'action': 'Click',
      'category': '주문서_오류안내(Dialog)',
      'label': '주문서 > 결제하기 > 배송지정보 미입력 안내 > 확인',
    },
    emptyName: {
      'action': 'Click',
      'category': '주문서_오류안내(Dialog)',
      'label': '주문서 > 결제하기 > 수령인정보 미입력 안내 > 확인',
    },
    emptyPhone: {
      'action': 'Click',
      'category': '주문서_오류안내(Dialog)',
      'label': '주문서 > 결제하기 > 휴대폰번호 미입력 안내 > 확인',
    },
    invalidPhone: {
      'action': 'Click',
      'category': '주문서_오류안내(Dialog)',
      'label': '주문서 > 결제하기 > 휴대폰번호 오입력 안내 > 확인',
    },
    invalidFirstDigit: {
      'action': 'Click',
      'category': '주문서_오류안내(Dialog)',
      'label': '주문서 > 결제하기 > 휴대폰앞세자리 오입력 안내 > 확인',
    },
    notCheckAgreement: {
      'action': 'Click',
      'category': '주문서_오류안내(Dialog)',
      'label': '주문서 > 결제하기 > 결제필수동의 미체크 안내 > 확인',
    },
    isSoldOut: {
      'action': 'Click',
      'category': '주문서_오류안내(Dialog)',
      'label': '주문서 > 결제하기 > 품절구매불가 안내 > 확인',
    },
  },
  orderInvalid: {
    retryOrder: {
      'action': 'Click',
      'category': '주문오류후 이동',
      'label': '주문오류 > 다시시도 버튼',
    },
    close: {
      'action': 'Click',
      'category': '주문오류 이탈',
      'label': '주문오류 > Topbar > 닫기버튼',
    },
    goBack: {
      'action': 'Click',
      'category': '주문오류 이탈',
      'label': '주문오류 > Topbar > 뒤로가기버튼',
    },
  },
  orderList: {
    goDetail: {
      'action': 'Click',
      'category': '주문내역_사용자액션',
      'label': '주문내역 > 상세보기 버튼',
    },
    tryConfirmOrder: {
      'action': 'Click',
      'category': '주문내역_사용자액션',
      'label': '주문내역 > 구매확정 버튼',
    },
    openPaymentMethods: {
      'action': 'Click',
      'category': '주문내역_사용자액션',
      'label': '주문내역 > 결제하기 버튼',
    },
    closePaymentMethods: {
      'action': 'Click',
      'category': '주문내역_사용자액션',
      'label': '주문내역 > 결제하기 버튼 > 닫기영역',
    },
    kakaopayPaymentMethod: {
      'action': 'Click',
      'category': '주문내역_사용자액션',
      'label': '주문내역 > 결제하기 버튼 > 결제 방식(kakaopay)',
    },
    nicePaymentMethod: {
      'action': 'Click',
      'category': '주문내역_사용자액션',
      'label': '주문내역 > 결제하기 버튼 > 결제 방식(카드)',
    },
    order: {
      'action': 'Click',
      'category': '주문내역_사용자액션',
      'label': '주문내역 > 결제 방식 > 결제하기 버튼',
    },
    close: {
      'action': 'Click',
      'category': '주문내역 이탈',
      'label': '주문내역 > Topbar > 닫기버튼',
    },
    goBack: {
      'action': 'Click',
      'category': '주문내역 이탈',
      'label': '주문내역 > Topbar > 뒤로가기버튼',
    },
    confirmOrderConfirm: {
      'action': 'Click',
      'category': '구매확정(Dialog)',
      'label': '주문내역 > 구매확정 > 구매확정컨펌 > 확인',
    },
    confirmOrderCancel: {
      'action': 'Click',
      'category': '구매확정(Dialog)',
      'label': '주문내역 > 구매확정 > 구매확정컨펌 > 취소',
    },
  },
  orderSuccess: {
    goOrderList: {
      'action': 'Click',
      'category': '주문완료후 이동',
      'label': '주문완료 > 주문내역보기 버튼',
    },
    goProductList: {
      'action': 'Click',
      'category': '주문완료후 이동',
      'label': '주문완료 > 상품더보기 버튼',
    },
    close: {
      'action': 'Click',
      'category': '주문완료 이탈',
      'label': '주문완료 > Topbar > 닫기버튼',
    },
    goBack: {
      'action': 'Click',
      'category': '주문완료 이탈',
      'label': '주문완료 > Topbar > 뒤로가기버튼',
    },
  },
  productDetail: {
    tabProductInfo: {
      'action': 'Click',
      'category': '상품상세 정보',
      'label': '상품상세 > 탭 > 상세정보',
    },
    tabSpreadProductInfo: {
      'action': 'Click',
      'category': '상품상세 정보',
      'label': '상품상세 > 탭 > 상세정보 > 상품필수정보 펼치기',
    },
    tabSellerInfo: {
      'action': 'Click',
      'category': '상품상세 정보',
      'label': '상품상세 > 탭 > 판매정보',
    },
    tabSpreadSellerInfo: {
      'action': 'Click',
      'category': '상품상세 정보',
      'label': '상품상세 > 탭 > 판매정보 > 판매자정보 펼치기',
    },
    tabSpreadExchange: {
      'action': 'Click',
      'category': '상품상세 정보',
      'label': '상품상세 > 탭 > 판매정보 > 교환반품정보 펼치기',
    },
    tabSpreadNotice: {
      'action': 'Click',
      'category': '상품상세 정보',
      'label': '상품상세 > 탭 > 판매정보 > 주의사항 펼치기',
    },
    goTop: {
      'action': 'Click',
      'category': '상품상세 정보',
      'label': '상품상세 > TOP버튼',
    },
    close: {
      'action': 'Click',
      'category': '상품상세 이탈',
      'label': '상품상세 > Topbar > 닫기버튼',
    },
    goBack: {
      'action': 'Click',
      'category': '상품상세 이탈',
      'label': '상품상세 > Topbar > 뒤로가기버튼',
    },
    openPurchase: {
      'action': 'Click',
      'category': '구매시도',
      'label': '상품상세 > 구매하기 버튼',
    },
    selectVariant: {
      'action': 'Click',
      'category': '세부 상품 선택',
      'label': '상품상세 > 구매하기 버튼 > 세부 상품 선택',
    },
    unSelectVariant: {
      'action': 'Click',
      'category': '세부 상품 선택 취소',
      'label': '상품상세 > 구매하기 버튼 > 세부 상품 선택 취소',
    },
    purchase: {
      'action': 'Click',
      'category': '구매시도',
      'label': '상품상세 > 옵션선택 > 주문하기 버튼',
    },
  },
  productList: {
    productThumbnail: {
      'action': 'Click',
      'category': '상품상세 진입',
      'label': '상품목록 > 상품선택(썸네일)',
    },
    productText: {
      'action': 'Click',
      'category': '상품상세 진입',
      'label': '상품목록 > 상품선택(텍스트)',
    },
    close: {
      'action': 'Click',
      'category': '상품목록 이탈',
      'label': '상품목록 > Topbar > 닫기버튼',
    },
    goBack: {
      'action': 'Click',
      'category': '상품목록 이탈',
      'label': '상품목록 > Topbar > 뒤로가기버튼',
    },
  },
  ecommerce: {
    productDetail: {
      'action': 'Impression',
      'category': 'Ecommerce',
      'label': '상품 상세 노출',
    },
    impression: {
      'action': 'Impression',
      'category': 'Ecommerce',
      'label': '상품 노출',
    },
    purchase: {
      'action': 'Purchase',
      'category': 'Ecommerce',
      'label': '상품 결제',
    },
  },
};

export default GaEvent;
