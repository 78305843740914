/* eslint-disable @typescript-eslint/camelcase */
import { RequestPayParam, RequestPaymentParam } from './types';

declare global {
  interface Window {
    IMP: {
      init: (key: string) => void | undefined;
      request_pay: (param: RequestPayParam, callback: (rsp: any) => void) => void | undefined;
    };
  }
}

if (typeof window !== 'undefined' && window.IMP) {
  window.IMP.init(process.env.IAMPORT_KEY as string);
}

// NOTE: https://www.iamport.kr/getstarted
// pg : 'inicis', // version 1.1.0부터 지원.
// pay_method : 'card',
// merchant_uid : 'merchant_' + new Date().getTime(),
// name : '주문명:결제테스트',
// amount : 14000,
// buyer_email : 'iamport@siot.do',
// buyer_name : '구매자이름',
// buyer_tel : '010-1234-5678',
// buyer_addr : '서울특별시 강남구 삼성동',
// buyer_postcode : '123-456',
export const requestPayment = ({
  pg,
  paymentMethod,
  merchantUid,
  name,
  amount,
  buyerEmail,
  buyerName,
  buyerTel,
  buyerAddr,
  buyerPostcode,
}: RequestPaymentParam): void => {
  window.IMP.request_pay({
    pg,
    pay_method: paymentMethod,
    merchant_uid: merchantUid,
    name,
    amount,
    buyer_email: buyerEmail,
    buyer_name: buyerName,
    buyer_tel: buyerTel,
    buyer_addr: buyerAddr,
    buyer_postcode: buyerPostcode,
    m_redirect_url: `${window.location.origin}/order/complete`,
  }, (rsp: any) => {
    // NOTE: PC web callback
    window.location.href = `${window.location.origin}/order/complete?imp_success=${rsp.success}&merchant_uid=${rsp.merchant_uid}&imp_uid=${rsp.imp_uid}`;
  });
};

export default typeof window === 'undefined' ? {} : window.IMP;
