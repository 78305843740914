import { types, IReferenceType, IAnyModelType } from 'mobx-state-tree';

import { Price } from './Price';
import { OptionValue } from './OptionValue';
import { Product } from './Product';
import { PriceAmount, PriceAmountInterface } from '~/common';

const INFINITE_QUANTITY = 99999;

export const Variant = types.model({
  _id: types.identifier,
  product: types.late((): IReferenceType<IAnyModelType> => types.reference(Product)),
  price: Price,
  discount: PriceAmount,
  types: types.array(OptionValue),
  quantity: types.optional(types.number, INFINITE_QUANTITY, [null, undefined]),
})
  .views(self => ({
    get allTypeOptionValue(): string {
      return self.types.map(({ option, variation }) => `${option.name} : ${variation.value}`).join(', ');
    },
    get additionalPriceAmount(): PriceAmountInterface {
      return self.price.sale.minus(self.product.price.sale);
    },
    get hasInfiniteQuantity(): boolean {
      return self.quantity >= INFINITE_QUANTITY;
    },
    get isSoldOut(): boolean {
      return self.quantity === 0;
    },
  }));
