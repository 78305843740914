import { types } from 'mobx-state-tree';

import { PriceAmount, PriceAmountInterface } from '.';

export const PriceRange = types.model('PriceRange', {
  min: PriceAmount,
  max: types.maybeNull(PriceAmount),
})
  .views((self) => ({
    get hasMinCondition(): boolean {
      return self.min.raw > 0;
    },
  }))
  .actions((self) => ({
    isComeIntoRange(priceAmount: PriceAmountInterface): boolean {
      const isComeIntoMinRange = priceAmount.minus(self.min).isBiggerThanZero;
      const isComeIntoMaxRange = self.max === null || self.max.minus(priceAmount).isBiggerThanZero;
      return isComeIntoMinRange && isComeIntoMaxRange;
    },
  }));
